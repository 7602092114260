@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "motifun";

/********************************************************
 *
 *      Fonts
 *
 ********************************************************/

@font-face {
  font-family: "texgyreadventorregular";
  src: url("/assets/fonts/texgyreadventor-regular-webfont.eot");
  src: url("/assets/fonts/texgyreadventor-regular-webfont.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/texgyreadventor-regular-webfont.woff") format("woff"),
  url("/assets/fonts/texgyreadventor-regular-webfont.ttf") format("truetype"),
  url("/assets/fonts/texgyreadventor-regular-webfont.svg#texgyreadventorregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "texgyreadventoritalic";
  src: url("/assets/fonts/texgyreadventor-italic-webfont.eot");
  src: url("/assets/fonts/texgyreadventor-italic-webfont.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/texgyreadventor-italic-webfont.woff") format("off"),
  url("/assets/fonts/texgyreadventor-italic-webfont.ttf") format("truetype"),
  url("/assets/fonts/texgyreadventor-italic-webfont.svg#texgyreadventoritalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('/assets/fonts/Material Icons'),
  local('/assets/fonts/MaterialIcons-Regular'),
  url('/assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
  url('/assets/fonts/MaterialIcons-Regular.woff') format('woff'),
  url('/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

/********************************************************
 *
 *             Scrollbar
 *
 ********************************************************/

::-webkit-scrollbar {
  width: 0.8rem;
  height: 2px;
  position: absolute;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #ff7317;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  /*background: #ffa163;*/
}

::-webkit-scrollbar-thumb:active {
  background: #ffa163;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/********************************************************
 *
 *             General
 *
 ********************************************************/

@charset "utf-8";
/* CSS Document */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'texgyreadventorregular', 'Roboto', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !important;
  font-size: 12px;
}

body {
  -webkit-font-smoothing: antialiased;
}

a {
  cursor: pointer;
  color: #3e3e3e;
}

a:hover {
  color: #ff7317;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

section {
  position: relative;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a fa-icon {
  cursor: pointer;
}

input::-moz-placeholder { /* Firefox 19+ */
  opacity: 1;
}

:focus {
  outline: none;
}

.clearer {
  clear: both;
}

.mobile {
  height: 100%;
}

/********************************************************
 *
 *      Overwrite nouiSlider
 *
 ********************************************************/

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
  background-color: #FF7317 !important;
}

.noUi-target.noUi-horizontal .noUi-tooltip {
  background-color: #FF7317 !important;
}

.noUi-connect {
  background: #FF7317 !important;
}

.noUi-target {
  background: #a8a7a7;
}

/********************************************************
*
*      Overwrite NGX-Timepicker
*
********************************************************/

.timepicker__header {
  background-color: $motifun-primary-color !important;
}

.timepicker-dial__control_editable::selection, .timepicker-dial__control_editable:focus {
  color: $motifun-primary-color !important;
  outline: $motifun-primary-color !important;
}

.timepicker-button {
  color: $motifun-primary-color !important;
}

.clock-face__clock-hand:after {
  background-color: $motifun-primary-color !important;
}

.clock-face__clock-hand {
  background-color: $motifun-primary-color !important;
}

.clock-face__number > span.active {
  background-color: $motifun-primary-color !important;
}

/********************************************************
*
*      Overwrite angular material
*
********************************************************/

mat-sidenav-content {
  /*otherwise there is an unnecessary  scrollbar on start page*/
  overflow: hidden !important;
}

mat-dialog-container {
  font-size: 14px;
  padding-top: 0 !important;
}

.img-preview mat-dialog-container {
  padding: 0 !important;
}

.mat-drawer-container {
  background-color: unset;
  color: unset;
}

.mat-form-field-prefix {
  margin-right: 3px;
}

#form-container mat-tab-body {
  padding: 10px 20px 0;
}

.form .flex-row mat-form-field:first-child {
  padding-left: 0;
}

.form .flex-row mat-form-field:last-child {
  padding-right: 0;
}

cdk-virtual-scroll-viewport.horizontal .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}

#chats .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

li.loading {
  display: block;
  text-align: center;
}

li.loading > mat-progress-spinner {
  margin: 0 auto;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-slider-horizontal .mat-slider-wrapper {
  left: 0 !important;
  right: 0 !important;
}

.event-content > mat-tab-group > mat-tab-header {
  position: relative;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.event-content > mat-tab-group > mat-tab-header:before {
  box-shadow: -15px 0 15px -15px inset;
  content: " ";
  height: 100%;
  left: -15px;
  position: absolute;
  top: 0;
  width: 15px;
}

.event-content > mat-tab-group > mat-tab-header:after {
  box-shadow: 15px 0 15px -15px inset;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  right: -15px;
  width: 15px;
}

@media all and (min-width: 600px) {
  html, body {
    font-size: 14px;
  }
}

@media all and (min-width: 900px) {
  html, body, mat-dialog-container {
    font-size: 16px;
  }
}

@media all and (min-width: 1921px) {
  html, body, mat-dialog-container {
    font-size: 18px;
  }
}

@media all and (min-width: 2921px) {
  html, body, mat-dialog-container {
    font-size: 25px;
  }
}
