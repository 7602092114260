/* For use in src/lib/core/theming/_palette.scss */
$md-motifunorange: (
  50 : #ffeee3,
  100 : #ffd5b9,
  200 : #ffb98b,
  300 : #ff9d5d,
  400 : #ff883a,
  500 : #ff7317,
  600 : #ff6b14,
  700 : #ff6011,
  800 : #ff560d,
  900 : #ff4307,
  A100 : #ffffff,
  A200 : #fff6f4,
  A400 : #ffcdc1,
  A700 : #ffb8a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #fff,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-dark-bg: (
  50 : #b0b0b0,
  100 : #898989,
  200 : #6d6d6d,
  300 : #4a4a4a,
  400 : #3a3a3a,
  500 : #414141,
  600 : #212121,
  700 : #1c1c1c,
  800 : #0c0c0c,
  900 : #000000,
  A100 : #c49191,
  A200 : #9b5454,
  A400 : #4f3a3a,
  A700 : #3b3535,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-lite-bg: (
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #fdfdfd,
  500 : #f5f5f5,
  600 : #dfdfdf,
  700 : #cfcfcf,
  800 : #c0c0c0,
  900 : #b1b1b1,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #fbfbfb,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
