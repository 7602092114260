@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './palettes.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$motifun-app-theme-primary: mat.define-palette($md-motifunorange);
$motifun-app-theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$motifun-app-theme-warn: mat.define-palette(mat.$red-palette);

$motifun-lite-theme: mat.define-light-theme($motifun-app-theme-primary, $motifun-app-theme-accent, $motifun-app-theme-warn);
$motifun-dark-theme: mat.define-dark-theme($motifun-app-theme-primary, $motifun-app-theme-accent, $motifun-app-theme-warn);

$dark-bg: mat.define-palette($md-dark-bg);
$lite-bg: mat.define-palette($md-lite-bg);

$background: map-get($motifun-lite-theme, background);
$bg2: mat.define-palette($md-lite-bg);

$motifun-primary-color: #ff7317;

.background {
  background-color: rgba(mat.get-color-from-palette($bg2), .8);
  color: #404040;
}

.text {
  color: #404040;
}

mat-sidenav, nav {
  background-color: rgba(0, 0, 0, 0.83) !important;
  color: white !important;
}

a {
  color: #3e3e3e;
}

.event-content > mat-tab-group > mat-tab-header {
  background-color: mat.get-color-from-palette($background, dialog);
  border-bottom: 0 !important;
}

.motifun-box, .box {
  background-color: mat.get-color-from-palette($background, dialog);
  color: #5b5b5b;
}

#form-container .motifun-box {
  background-color: rgba(245, 245, 245, 0.8);
}

#arrow {
  border-bottom-color: rgba(mat.get-color-from-palette($background, dialog), 1) !important;
}

.logo-container {
  background-color: rgba(mat.get-color-from-palette($bg2), .8);
  border-color: mat.get-color-from-palette($motifun-app-theme-primary) !important;
}

.section-headline {
  color: #3e3e3e;
  border-bottom: 1px solid #3e3e3e;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($motifun-lite-theme);
.dark-theme {
  // Dark theme
  @include mat.all-component-themes($motifun-dark-theme);

  $background: map-get($motifun-dark-theme, background);
  $fg: map-get($motifun-dark-theme, foreground);
  $bg2: mat.define-palette($md-dark-bg);

  /*background-color: mat.get-color-from-palette($background, background);*/

  mat-tab-group > mat-tab-header {
    background-color: mat.get-color-from-palette($background, dialog);
    border-bottom: 0 !important;
  }

  a {
    color: #e6e6e6;
  }

  .motifun-box, .box {
    background-color: mat.get-color-from-palette($background, dialog);
    color: #e6e6e6;
  }

  .text {
    color: mat.get-color-from-palette($fg, text);
  }

  .dialog {
    background-color: mat.get-color-from-palette($background, dialog) !important;
    color: #e6e6e6;
  }

  #arrow {
    border-bottom-color: rgba(mat.get-color-from-palette($background, dialog), 1) !important;
  }

  .section-headline {
    color: #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
}
