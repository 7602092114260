@import "themes/motifun";

:root {
  --width: 100%;
}

/*replace*/
/*button > span > fa-icon {
  margin: 0 0.8rem;
}*/

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-05 {
  --width: calc(100% * 0.05);
  width: var(--width);
}

.col-1 {
  --width: calc(100% * 0.1);
  width: var(--width);
}

.col-15 {
  --width: calc(100% * 0.15);
  width: var(--width);
}

.col-2 {
  --width: calc(100% * 0.2);
  width: var(--width);
}

.col-25 {
  --width: calc(100% * 0.25);
  width: var(--width);
}

.col-3 {
  --width: calc(100% * 0.3);
  width: var(--width);
}

.col-33 {
  --width: calc(100% / 3);
  width: var(--width);
}

.col-35 {
  --width: calc(100% * 0.35);
  width: var(--width);
}

.col-4 {
  --width: calc(100% * 0.4);
  width: var(--width);
}

.col-45 {
  --width: calc(100% * 0.45);
  width: var(--width);
}

.col-5 {
  --width: calc(100% * 0.5);
  width: var(--width);
}

.col-55 {
  --width: calc(100% * 0.55);
  width: var(--width);
}

.col-6 {
  --width: calc(100% * 0.6);
  width: var(--width);
}

.col-65 {
  --width: calc(100% * 0.65);
  width: var(--width);
}

.col-66 {
  --width: calc(100% / 6);
  width: var(--width);
}

.col-7 {
  --width: calc(100% * 0.7);
  width: var(--width);
}

.col-75 {
  --width: calc(100% * 0.75);
  width: var(--width);
}

.col-8 {
  --width: calc(100% * 0.8);
  width: var(--width);
}

.col-85 {
  --width: calc(100% * 0.85);
  width: var(--width);
}

.col-9 {
  --width: calc(100% * 0.9);
  width: var(--width);
}

.col-95 {
  --width: calc(100% * 0.95);
  width: var(--width);
}

.col-10 {
  --width: calc(100% * 1.0);
  width: var(--width);
}

/*___________*/


.flex {
  display: flex;
}

.flex.col {
  flex-direction: column;
}

.flex.row {
  flex-direction: row;
}

.flex.center {
  justify-content: center;
  align-items: center;
}

.flex.center-h {
  align-items: center;
}

.flex.center-v {
  justify-content: center;
}

/*
.row {
  margin: 5px 0;
}

.row:first-of-type {
  margin: 0 0 5px 0;
}

.row:last-of-type {
  margin: 5px 0 0 0;
}

.row:only-of-type {
  margin: 0;
}

.inner-row {
  margin: 0;
}
 */

.row > .input-field, .row > mat-form-field, .col > mat-form-field, .col > .input-field {
  padding: 10px 10px 0;
  margin-bottom: 8px;
}

@media all and (max-width: 56.1875em) {
  /* small-row just for widths to 899px */
  .s-row {
    margin: 10px 0;
  }

  .s-row:first-of-type {
    margin: 0 0 10px 0;
  }

  .s-row:last-of-type {
    margin: 10px 0 0 0;
  }

  .s-row:only-of-type {
    margin: 0;
  }
}


@media screen and (min-width: 78.1875em) {

  .main-fixed {
    /*optional class for row-flex to make sticky cols beside scrollable cols*/
    /*needs class overflow-y be added to the scrollable cols*/
    height: calc(100vh - 30px);
  }
}

.main-gap {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.f-fix {
  flex: none;
}

.col.s05 {
  --width: calc(100% * 0.05);
  width: calc(100% * 0.05);
}

.col.s1 {
  --width: calc(100% * 0.1);
  width: calc(100% * 0.1);
}

.col.s15 {
  --width: calc(100% * 0.15);
  width: calc(100% * 0.15);
}

.col.s2 {
  --width: calc(100% * 0.2);
  width: calc(100% * 0.2);
}

.col.s25 {
  --width: calc(100% * 0.25);
  width: calc(100% * 0.25);
}

.col.s3 {
  --width: calc(100% * 0.3);
  width: calc(100% * 0.3);
}

.col.s33 {
  --width: calc(100% / 3);
  width: calc(100% / 3);
}

.col.s35 {
  --width: calc(100% * 0.35);
  width: calc(100% * 0.35);
}

.col.s4 {
  --width: calc(100% * 0.4);
  width: calc(100% * 0.4);
}

.col.s45 {
  --width: calc(100% * 0.45);
  width: calc(100% * 0.45);
}

.col.s5 {
  --width: calc(100% * 0.5);
  width: calc(100% * 0.5);
}

.col.s55 {
  --width: calc(100% * 0.55);
  width: calc(100% * 0.55);
}

.col.s6 {
  --width: calc(100% * 0.6);
  width: calc(100% * 0.6);
}

.col.s65 {
  --width: calc(100% * 0.65);
  width: calc(100% * 0.65);
}

.col.s66 {
  --width: calc(100% / 3 * 2);
  width: calc(100% / 3 * 2);
}

.col.s7 {
  --width: calc(100% * 0.7);
  width: calc(100% * 0.7);
}

.col.s75 {
  --width: calc(100% * 0.75);
  width: calc(100% * 0.75);
}

.col.s8 {
  --width: calc(100% * 0.8);
  width: calc(100% * 0.8);
}

.col.s85 {
  --width: calc(100% * 0.85);
  width: calc(100% * 0.85);
}

.col.s9 {
  --width: calc(100% * 0.9);
  width: calc(100% * 0.9);
}

.col.s95 {
  --width: calc(100% * 0.95);
  width: calc(100% * 0.95);
}

.col.s10 {
  --width: calc(100% * 1.0);
  width: calc(100% * 1.0);
}

.margin {
  width: calc(var(--width) - 1%);
  margin: 0 0.5%;
}

.margin:first-of-type {
  margin: 0 1% 0 0 !important;
}

.margin:last-of-type {
  margin: 0 0 0 1% !important;
}

@media screen and (max-width: 56.1875em) {
  /* horizontal margin */
  .s-margin-h {
    width: calc(var(--width) - 1%);
    margin: 0 0.5%;
  }

  .s-margin-h:first-of-type {
    margin: 0 1% 0 0 !important;
  }

  .s-margin-h:last-of-type {
    margin: 0 0 0 1% !important;
  }

  /* vertical margin */
  .s-margin-v {
    width: calc(var(--width) - 1%);
    margin: 0.5%;
  }

  .s-margin-v:first-of-type {
    margin: 0 0 1% 0 !important;
  }

  .s-margin-v:last-of-type {
    margin: 1% 0 0 0 !important;
  }

}


@media screen and (min-width: 56.25em) {
  /* horizontal margin */
  .m-margin-h {
    width: calc(var(--width) - 1%);
    margin: 0 0.5%;
  }

  .m-margin-h:first-of-type {
    margin: 0 1% 0 0 !important;
  }

  .m-margin-h:last-of-type {
    margin: 0 0 0 1% !important;
  }

  /* vertical margin */
  .m-margin-v {
    width: calc(var(--width) - 1%);
    margin: 0.5% 0;
  }

  .m-margin-v:first-of-type {
    margin: 0 0 1% 0 !important;
  }

  .m-margin-v:last-of-type {
    margin: 1% 0 0 0 !important;
  }

}

@media all and (max-width: 1094px) {
  .col.m05 {
    --width: calc(100% * 0.05);
    width: calc(100% * 0.05);
  }

  .col.m1 {
    --width: calc(100% * 0.1);
    width: calc(100% * 0.1);
  }

  .col.m15 {
    --width: calc(100% * 0.15);
    width: calc(100% * 0.15);
  }

  .col.m2 {
    --width: calc(100% * 0.2);
    width: calc(100% * 0.2);
  }

  .col.m25 {
    --width: calc(100% * 0.25);
    width: calc(100% * 0.25);
  }

  .col.m3 {
    --width: calc(100% * 0.3);
    width: calc(100% * 0.3);
  }

  .col.m33 {
    --width: calc(100% / 3);
    width: calc(100% / 3);
  }

  .col.m35 {
    --width: calc(100% * 0.35);
    width: calc(100% * 0.35);
  }

  .col.m4 {
    --width: calc(100% * 0.4);
    width: calc(100% * 0.4);
  }

  .col.m45 {
    --width: calc(100% * 0.45);
    width: calc(100% * 0.45);
  }

  .col.m5 {
    --width: calc(100% * 0.5);
    width: calc(100% * 0.5);
  }

  .col.m55 {
    --width: calc(100% * 0.55);
    width: calc(100% * 0.55);
  }

  .col.m6 {
    --width: calc(100% * 0.6);
    width: calc(100% * 0.6);
  }

  .col.m65 {
    --width: calc(100% * 0.65);
    width: calc(100% * 0.65);
  }

  .col.m66 {
    --width: calc(100% / 3 * 2);
    width: calc(100% / 3 * 2);
  }

  .col.m7 {
    --width: calc(100% * 0.7);
    width: calc(100% * 0.7);
  }

  .col.m75 {
    --width: calc(100% * 0.75);
    width: calc(100% * 0.75);
  }

  .col.m8 {
    --width: calc(100% * 0.8);
    width: calc(100% * 0.8);
  }

  .col.m85 {
    --width: calc(100% * 0.85);
    width: calc(100% * 0.85);
  }

  .col.m9 {
    --width: calc(100% * 0.9);
    width: calc(100% * 0.9);
  }

  .col.m95 {
    --width: calc(100% * 0.95);
    width: calc(100% * 0.95);
  }

  .col.m10 {
    --width: calc(100% * 1.0);
    width: calc(100% * 1.0);
  }

  .flex.m-row {
    flex-direction: row;
  }

  .flex.m-col {
    flex-direction: column;
  }

  .flex.m-rrow {
    flex-direction: row-reverse;
  }

  .flex.m-rcol {
    flex-direction: column-reverse;
  }
}


@media screen and (min-width: 78.125em) {
  /* horizontal margin */
  .l-margin-h {
    width: calc(var(--width) - 1%);
    margin: 0 0.5%;
  }

  .l-margin-h:first-of-type {
    margin: 0 1% 0 0 !important;
  }

  .l-margin-h:last-of-type {
    margin: 0 0 0 1% !important;
  }

  /* vertical margin */
  .l-margin-v {
    width: calc(var(--width) - 1%);
    margin: 0.5% 0;
  }

  .l-margin-v:first-of-type {
    margin: 0 0 1% 0 !important;
  }

  .l-margin-v:last-of-type {
    margin: 1% 0 0 0 !important;
  }
}

@media all and (min-width: 1095px) {
  .flex.row {
    flex-direction: row;
  }

  .flex.col {
    flex-direction: column;
  }

  .flex.rrow {
    flex-direction: row-reverse;
  }

  .flex.rcol {
    flex-direction: column-reverse;
  }
}

s-full {
  width: var(--width);
}

m-full {
  width: var(--width);
}

.overflow-y {
  overflow-y: auto;
}

.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.hide {
  display: none !important
}

.fill-x {
  width: 100%;
}

.fill-y {
  height: 100%;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.flex.fill {
  flex: 1 1 auto;
}

.flex-fill-x {
  flex: 0 1 auto;
}

.abs {
  position: absolute;
}

.fixed {
  position: fixed;
}

.rel {
  position: relative;
}

.bottom {
  position: absolute !important;
  bottom: 18px
}

.flex-bottom {
  margin-top: auto !important;
}

.circle {
  border-radius: 50%;
  margin-bottom: 0.8rem;
}

.fullscreen {
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
}

.motifun.bg {
  background-color: #ff7317;
}

.motifun.text {
  color: #ff7317 !important;
}

.motifun.border {
  border-color: #ff7317 !important;
}

.pointer {
  cursor: pointer;
}

.clearer {
  clear: both;
}

.bttm {
  bottom: 5px;
  text-align: center;
}

.inline {
  display: inline-block;
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
  color: #ff7317;
  cursor: pointer;
}

.action:hover > fa-icon, .action:hover > p, .action:hover > span {
  color: #FF7317 !important;
  cursor: pointer;
}

div.link:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-link {
  font-weight: bold;
}

.text-link:hover {
  text-decoration: underline;
}

.text.start {
  text-align: start;
}

.text.center {
  text-align: center;
}

.text.end {
  text-align: end;
}

.editable {
  position: relative;
}

.editable:hover .edit-icon {
  display: block;
}

.edit-icon {
  display: none;
  right: 5px;
  top: 5px;
  font-size: 1.3em;
  z-index: 1;
}

.edit-icon:hover {
  color: #FF7317;
  cursor: pointer;
}

.icon-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img {
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img.preview {
  background-size: contain;
  object-fit: contain;
}

.img.xsmall {
  width: 48px;
  height: 48px;
}

.img.small {
  width: 72px;
  height: 72px;
}

.img.medium1 {
  width: 105px;
  height: 105px;
}

.img.medium2 {
  width: 125px;
  height: 125px;
}

.img.medium3 {
  width: 220px;
  height: 220px;
}

.img.large {
  width: 300px;
  height: 300px;
}

.img.xlarge {
  width: 45em;
  height: 45em;
}

.motifun-border {
  border: 2px solid #ff7317 !important;
}

.lite-border {
  border: 2px solid #3a3a3a;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.dark-border {
  border: 2px solid #d9d9d9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.logo-container {
  margin: 0 auto 60px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22rem;
  height: 22rem;
  border: 2px solid;
  border-radius: 50%;
}

.logo-container:hover {
  cursor: pointer;
}

.logo-container > .logo {
  width: 16rem;
  height: 16rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.logo-container.small {
  width: 6.5rem;
  height: 6.5rem;
  background-color: unset;
}

.logo-container > .logo.small {
  width: 5rem;
  height: 5rem;
}

.form {
  padding: 0 0 0.8rem 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form > button {
  margin-top: 1.1rem !important;
}

.editable .flex-row, .editable .row {
  padding: 0 0.8rem;
}

.section-headline {
  display: block;
  width: 100%;
  padding: 0.3rem 0;
  margin: 0 0 0.8rem 0;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.content {
  width: 90%;
  max-width: 1100px;
  margin: 56px auto 85px;
  transition: 0.2s all ease-in;

  /* Flex-Properties */
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.scrollable-content {
  margin: 0 auto;
  width: 100%;
}

.scrollable-content > div {
  width: 100%;
  padding-top: 0.8rem;
  position: relative;
}

.content-text {
  color: grey;
  font-size: 1.2em;
  text-align: left;
}

.container-content {
  padding: 10px calc((100% - 1400px) / 2);
}

.motifun-box {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
}

.motifun-box.section {
  padding: 5px 40px;
}

.motifun-box.section:not(:first-child) {
  margin: 20px 0 0;
}

.motifun-box .title {
  width: 100%;
  font-size: 1.4em;
  margin: 0.8rem;
  text-align: center;
}

.card-item {
  border-radius: 5px;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, .06), 1px 3px 8px rgba(39, 44, 49, .03);
  transition: all .5s ease;
  backface-visibility: hidden;
}

.card-item:hover {
  box-shadow: 8px 28px 50px rgba(39, 44, 49, .07), 1px 6px 12px rgba(39, 44, 49, .04);
  transition: all .4s ease;
  transform: translate3D(0, -1px, 0) scale(1.01);
}

.scroll-btn-sec a {
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}

.scroll-btn-sec a:hover {
  opacity: .5;
}

.scroll-btn-sec {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
}

.scroll-btn-sec a {
  padding-top: 60px;
}

.scroll-btn-sec a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 1px solid #fff;
  border-radius: 100%;
  box-sizing: border-box;
}

.scroll-btn-sec a span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.scroll-btn-sec a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, .1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.nav-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-size: 2.5em;
}

.nav-arrows:hover {
  color: #ff7317;
  cursor: pointer;
}

.title-box {
  margin: 5px 0 12px;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
}

.title-box > * {
  margin: 0;
}

.title-box > h1 {
  font-size: 2em;
}

.title-box > h2 {
  font-size: 1.8em;
}

.title-box > h3 {
  font-size: 1.4em;
}

.title-box > p {
  margin: 0;
  font-size: 2em;
}

.title-box > hr {
  width: 45%;
  margin: 0;
  height: 0;
  border: none;
  border-bottom: 3px solid #FF7317;
}

@media all and (min-width: 900px) {
  .scrollable-content {
    width: 90%;
  }
}

@media all and (max-width: 53.571em) {
  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.3em;
  }

  h5 {
    font-size: 1.2em;
  }

  h6 {
    font-size: 1.1em;
  }

  .mobile {
    display: block;
  }

  .wide {
    display: none;
  }
}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
  .edit-icon {
    display: block;
  }
}

@media all and (min-width: 600px) {
  /*
  > 600px
   */
  .content {
    margin-top: 64px;
  }
}

@media all and (max-height: 700px/*TODO:*/
) {
  .scroll-btn-sec a {
    bottom: -42px;
  }
}

@media all and (max-height: 600px) {
  .scroll-btn-sec {
    display: none;
  }
}

@media all and (min-height: 800px) {
  .scroll-btn-sec {
    display: block;
  }
}

li.facebook:hover, button.facebook {
  background-color: #3b5998 !important;
  color: white;
}

li.twitter:hover, button.twitter {
  background-color: #4099FF !important;
  color: white;
}

li.instagram:hover, button.instagram {
  background-color: #f56450 !important;
  color: white;
}

li.google:hover, button.google {
  background-color: #fff !important;
  color: red;
}

a.facebook:hover {
  color: #3B5998 !important;
}

a.instagram:hover {
  color: #cd486b !important;
}

a.twitter:hover {
  color: #00aced !important;
}

.color-effect.lite {
  background: rgba(231, 231, 231, 0.9);
}

.color-effect.dark {
  background: rgba(58, 58, 58, 0.9);
}

.color-effect.ibiza-sunset {
  background-image: -webkit-linear-gradient(45deg, rgba(238, 9, 121, 0.85), rgba(255, 106, 0, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(238, 9, 121, 0.85), rgba(255, 106, 0, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(238, 9, 121, 0.85), rgba(255, 106, 0, 0.9));
  background-image: linear-gradient(45deg, rgba(238, 9, 121, 0.85), rgba(255, 106, 0, 0.9));
}

.color-effect.pomegranate {
  background-image: -webkit-linear-gradient(45deg, rgba(155, 60, 183, 0.85), rgba(255, 57, 111, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(155, 60, 183, 0.85), rgba(255, 57, 111, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(155, 60, 183, 0.85), rgba(255, 57, 111, 0.9));
  background-image: linear-gradient(45deg, rgba(155, 60, 183, 0.85), rgba(255, 57, 111, 0.9));
}

.color-effect.king-yna {
  background-image: -webkit-linear-gradient(45deg, rgba(26, 42, 108, 0.85), rgba(178, 31, 31, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(26, 42, 108, 0.85), rgba(178, 31, 31, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(26, 42, 108, 0.85), rgba(178, 31, 31, 0.9));
  background-image: linear-gradient(45deg, rgba(26, 42, 108, 0.85), rgba(178, 31, 31, 0.9));
}

.color-effect.flickr {
  background-image: -webkit-linear-gradient(45deg, rgba(51, 0, 27, 0.85), rgba(255, 0, 132, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(51, 0, 27, 0.85), rgba(255, 0, 132, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(51, 0, 27, 0.85), rgba(255, 0, 132, 0.9));
  background-image: linear-gradient(45deg, rgba(51, 0, 27, 0.85), rgba(255, 0, 132, 0.9));
}

.color-effect.purple-bliss {
  background-image: -webkit-linear-gradient(45deg, rgba(54, 0, 51, 0.85), rgba(11, 135, 147, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(54, 0, 51, 0.85), rgba(11, 135, 147, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(54, 0, 51, 0.85), rgba(11, 135, 147, 0.9));
  background-image: linear-gradient(45deg, rgba(54, 0, 51, 0.85), rgba(11, 135, 147, 0.9));
}

.color-effect.man-of-steel {
  background-image: -webkit-linear-gradient(45deg, rgba(120, 2, 6, 0.85), rgba(6, 17, 97, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(120, 2, 6, 0.85), rgba(6, 17, 97, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(120, 2, 6, 0.85), rgba(6, 17, 97, 0.9));
  background-image: linear-gradient(45deg, rgba(120, 2, 6, 0.85), rgba(6, 17, 97, 0.9));
}

.color-effect.purple-love {
  background-image: -webkit-linear-gradient(45deg, rgba(204, 43, 94, 0.85), rgba(117, 58, 136, 0.9));
  background-image: -moz-linear-gradient(45deg, rgba(204, 43, 94, 0.85), rgba(117, 58, 136, 0.9));
  background-image: -o-linear-gradient(45deg, rgba(204, 43, 94, 0.85), rgba(117, 58, 136, 0.9));
  background-image: linear-gradient(45deg, rgba(204, 43, 94, 0.85), rgba(117, 58, 136, 0.9));
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 20px solid #CECECE;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #CECECE;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #CECECE;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #CECECE;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/********************************************************
 *
 *  G E N E R A L   B R E A K P O I N T S
 *
 ********************************************************/

